export const kUserInputDebounce = 1000;
export const kThemeAnimationDuration = 200;

export const kOneLineTextLimit = 70;

export const kUserPreferencesCalendarTab = 'web-calendar-tab';
export const kUserPreferencesCalendarTabValues = [
    'timeline',
    'timeGrid',
    'calendar',
    'cards',
];
export const kUserPreferencesCalendarView = 'web-calendar-view';
export const kUserPreferencesCalendarViewValues = [
    'day',
    'week',
    'month',
];
export const kUserPreferencesTimesheetsViewMode = 'timesheets-view-mode';
export const kUserPreferencesNewJobCalendarVisible = 'new-job-calendar-visible';
export const kUserPreferencesAllCompanyNotifications = 'all-company-notifications';
export const kUserPreferencesSelectClientListContactInfo = 'select-client-list-contact-info';
export const kUserPreferencesNotificationsVisitStatusToSolve = 'notifications-visit-status-to-solve';

export const kJobEmployeeStatusGpsDistanceValid = 200;

export const kTopicUsers = "users"
export const kTopicClients = "clients"
export const kTopicClientContacts = "client_contacts"
export const kTopicCompanies = "companies"
export const kTopicCompanyEmployees = "company_employees"
export const kTopicJobFormTemplates = "job_form_templates"
export const kTopicJobs = "jobs"
export const kTopicVisits = "visits"
export const kTopicJobProtocols = 'job_protocols'
export const kTopicLocations = "locations"
export const kTopicLocationPlaces = "location_places"
export const kTopicProductTemplates = "product_templates"
export const kTopicProducts = "products"
export const kTopicMaterialTemplates = "material_templates"
export const kTopicMaterials = "materials"
export const kTopicStorage = "storage"

export const kActionView = "view"
export const kActionCreate = "create"
export const kActionUpdate = "update"
export const kActionDelete = "delete"

export const kPermissionsCompany = 'company';
export const kPermissionsCompanySubscription = 'company_subscription';
export const kPermissionsJobs = 'jobs';
export const kPermissionsVisitCanBeResponsible = "visit_can_be_responsible";
export const kPermissionsVisitMyStatus = "visit_my_status";
export const kPermissionsVisitClientDetails = "visit_client_details";
export const kPermissionsVisitClientDetailsContacts = 'visit_client_details_contacts';
export const kPermissionsVisitWorkers = "visit_workers";
export const kPermissionsVisitWorkersContacts = "visit_workers_contacts";
export const kPermissionsVisitAttachments = "visit_attachments";
export const kPermissionsVisitProtocols = "visit_protocols";
export const kPermissionsClients = 'clients';
export const kPermissionsWorkers = 'workers';
export const kPermissionsTimesheets = 'timesheets';
export const kPermissionsTimesheetApprove = "timesheet_approve";
export const kPermissionsTimesheetApproveAllRoles = "timesheet_approve_all_roles";
export const kPermissionsTimesheetExport = "timesheet_export";
export const kPermissionsProducts = 'products';
export const kPermissionsProductsPrices = 'products_prices';
export const kPermissionsMaterials = 'materials';
export const kPermissionsForms = 'forms';
export const kPermissionsFormsFillElement = "forms_fill_element";
export const kPermissionsMobileCompanyView = 'mobile_company_view';
