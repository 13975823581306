import {createContext, Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState} from "react";
import Debouncer from "../utils/Debouncer";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import {kThemeAnimationDuration} from "./constants";
import {useComponentDefaultProps} from "@mantine/core";
import {IAppContext} from "../App";
import QueryString from "qs";
import {listenToEventSystem, unListenToEventSystem} from "../service/EventSystemService";
import IEventSystemListener from "../model/EventSystemListener";
import IEventSystemNotification from "../model/firestore/EventSystemNotification";
import {uniqueArray} from "../utils/Utils";

export const k30Minutes = 1000 * 60 * 30;

export interface IRestApiClientContext {
    restUriBuilder: (uri: string, parameters?: Record<string, any>) => string;
    subscriptions: IRestApiSubscription[];
    restApiGet: (params: IRestApiParams) => AbortController | undefined;
    restApiPost: (params: IRestApiParams) => AbortController | undefined;
    restApiDelete: (params: IRestApiParams) => AbortController | undefined;
    getDataForSubscriptions: (topic: string) => void;
    subscribe: (
        topic: string,
        params: IRestApiParams,
        validateEvent: (notification: IEventSystemNotification[]) => boolean,
    ) => IRestApiSubscription;
}

export const RestApiClientContext = createContext<IRestApiClientContext>({} as IRestApiClientContext);

export enum FetchPolicy {
    None,
    CacheAndNetwork,
    NetworkOnly,
    CacheOnly,
}

export interface IRestApiProviderProps {
    children: ReactNode;
    appContext: IAppContext;
    baseUri: string;
    defaultFetchPolicy?: FetchPolicy;
    pollSubscriptionsInterval?: number;
}

const defaultProps: Partial<IRestApiProviderProps> = {
    defaultFetchPolicy: FetchPolicy.CacheAndNetwork,
};

/**
 * Component which is REST API provider.
 */
export default function RestApiProvider(props: IRestApiProviderProps) {
    const {
        children,
        appContext,
        baseUri,
        defaultFetchPolicy,
        pollSubscriptionsInterval
    } = useComponentDefaultProps('RestApiProvider', defaultProps, props);

    const authEmail = appContext.authUser?.email;
    const authIdToken = appContext.authUser?.idToken;

    const [subscriptions, setSubscriptions] = useState<IRestApiSubscription[]>([]);
    const brodcastDebouncersByFetchPolicyByTopic = useRef<Record<FetchPolicy, Record<string, Debouncer>>>({ [FetchPolicy.None]: {}, [FetchPolicy.CacheAndNetwork]: {}, [FetchPolicy.NetworkOnly]: {}, [FetchPolicy.CacheOnly]: {} });
    const brodcastDebouncersByFetchPolicyByIdentifier = useRef<Record<FetchPolicy, Record<string, Debouncer>>>({ [FetchPolicy.None]: {}, [FetchPolicy.CacheAndNetwork]: {}, [FetchPolicy.NetworkOnly]: {}, [FetchPolicy.CacheOnly]: {} });
    const [topicsToGetDataForSubscriptions, setTopicsToGetDataForSubscriptions] = useState<string[]>([]);
    const [identifiersToGetDataForSubscriptions, setIdentifiersToGetDataForSubscriptions] = useState<string[]>([]);
    const allTopicsOfSubscriptions = useRef<string[]>([]);
    const cache = useRef<Record<string, any>>({});
    const pollSubscriptionsIntervalRef = useRef<NodeJS.Timer>();

    useEffect(() => {
        allTopicsOfSubscriptions.current = subscriptions.map((subscription) => {
            return subscription.topic;
        });
    }, [subscriptions]);

    useEffect(() => {
        if (authEmail && authIdToken) {
            allTopicsOfSubscriptions.current.forEach((topic) => {
                setTopicsToGetDataForSubscriptions((prev) => {
                    return [...prev, topic];
                });
            });
        }
    }, [authEmail, authIdToken]);

    useEffect(() => {
        if (pollSubscriptionsIntervalRef.current) {
            clearInterval(pollSubscriptionsIntervalRef.current);
        }

        if (pollSubscriptionsInterval) {
            pollSubscriptionsIntervalRef.current = setInterval(() => {
                allTopicsOfSubscriptions.current.forEach((topic) => {
                    setTopicsToGetDataForSubscriptions((prev) => {
                        return [...prev, topic];
                    });
                });
            }, pollSubscriptionsInterval);
        }

        return () => {
            if (pollSubscriptionsIntervalRef.current) {
                clearInterval(pollSubscriptionsIntervalRef.current);
            }
        };
    }, [pollSubscriptionsInterval]);

    /**
     * Create default request headers.
     */
    const createDefaultRequestHeaders = (): Record<string, string> => {
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
        };

        if (authEmail && authIdToken) {
            // headers['Authorization'] = `Basic ${btoa(`${authEmail}:${authIdToken}`)}`;
            headers['Authorization'] = `Basic ${Buffer.from(`${authEmail}:${authIdToken}`).toString('base64')}`;
        }

        return headers;
    };

    /**
     * Get data from cache for identifier.
     */
    const getDataFromCache = (identifier: string): any => {
        return cache.current[identifier];
    };

    /**
     * Set data to cache for identifier.
     */
    const setDataToCache = (identifier: string, data: any) => {
        cache.current[identifier] = data;
    };

    /**
     * Build GET URI with baseUri and parameters.
     */
    const restUriBuilder = (uri: string, parameters?: Record<string, any>): string => {
        const uriWithParameters = parameters ? uri + '?' + QueryString.stringify(parameters, {
            arrayFormat: 'repeat',
        }) : uri;

        return baseUri + uriWithParameters;
    };

    /**
     * REST API GET request.
     * We are using axios here.
     */
    const restApiGet = (params: IRestApiParams): AbortController | undefined => {
        const identifier = createIdentifier('get', params.uri, params.params ?? {});
        const fetchPolicy = params.fetchPolicy ?? defaultFetchPolicy!;

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.CacheOnly) {
            const data = getDataFromCache(identifier);

            if (data || fetchPolicy === FetchPolicy.CacheOnly) {
                params.onData(data);
            }
        }

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.NetworkOnly) {
            params.setLoading?.(true);

            const controller = new AbortController();

            axios.get(`${baseUri}${params.uri}`, {
                headers: {
                    ...createDefaultRequestHeaders(),
                    ...params.headers,
                },
                params: params.params,
                paramsSerializer: (params) => {
                    return QueryString.stringify(params, {
                        arrayFormat: 'repeat',
                    });
                },
                signal: controller.signal,
            }).then((response) => {
                params.setLoading?.(false);

                if (response.status !== 200) {
                    throw new Error(`Request failed with status ${response.status}\n${response.data}`);
                }

                setDataToCache(identifier, response.data);

                params.onData(response.data);
            }).catch((error) => {
                params.setLoading?.(false);

                console.error('TCH_d', error);

                setDataToCache(identifier, null);
                params.onData(null);

                if (params.onError) {
                    params.onError(error as Error);
                }
            });

            return controller;
        }

        return undefined;
    };

    /**
     * REST API POST request.
     * We are using axios here.
     */
    const restApiPost = (params: IRestApiParams): AbortController | undefined => {
        const identifier = createIdentifier('post', params.uri, params.params ?? {});
        const fetchPolicy = params.fetchPolicy ?? defaultFetchPolicy!;

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.CacheOnly) {
            const data = getDataFromCache(identifier);

            if (data || fetchPolicy === FetchPolicy.CacheOnly) {
                params.onData(data);
            }
        }

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.NetworkOnly) {
            params.setLoading?.(true);

            const controller = new AbortController();

            axios.post(`${baseUri}${params.uri}`, params.params, {
                headers: {
                    ...createDefaultRequestHeaders(),
                    ...params.headers,
                },
                signal: controller.signal,
            }).then((response) => {
                params.setLoading?.(false);

                if (response.status !== 200) {
                    throw new Error(`Request failed with status ${response.status}\n${response.data}`);
                }

                setDataToCache(identifier, response.data);

                params.onData(response.data);
            }).catch((error) => {
                params.setLoading?.(false);

                console.error('TCH_d', error);

                setDataToCache(identifier, null);
                params.onData(null);

                if (params.onError) {
                    params.onError(error as Error);
                }
            });

            return controller;
        }

        return undefined;
    };

    /**
     * REST API DELETE request.
     * We are using axios here.
     */
    const restApiDelete = (params: IRestApiParams): AbortController | undefined => {
        const identifier = createIdentifier('delete', params.uri, params.params ?? {});
        const fetchPolicy = params.fetchPolicy ?? defaultFetchPolicy!;

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.CacheOnly) {
            const data = getDataFromCache(identifier);

            if (data || fetchPolicy === FetchPolicy.CacheOnly) {
                params.onData(data);
            }
        }

        if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.NetworkOnly) {
            params.setLoading?.(true);

            const controller = new AbortController();

            axios.delete(`${baseUri}${params.uri}`, {
                headers: {
                    ...createDefaultRequestHeaders(),
                    ...params.headers,
                },
                params: params.params,
                signal: controller.signal,
            }).then((response) => {
                params.setLoading?.(false);

                if (response.status !== 200) {
                    throw new Error(`Request failed with status ${response.status}\n${response.data}`);
                }

                setDataToCache(identifier, response.data);

                params.onData(response.data);
            }).catch((error) => {
                params.setLoading?.(false);

                console.error('TCH_d', error);

                setDataToCache(identifier, null);
                params.onData(null);

                if (params.onError) {
                    params.onError(error as Error);
                }
            });

            return controller;
        }

        return undefined;
    };

    /**
     * Get Subscriptions by topic.
     * Group by identifier.
     * Call getData on each group.
     * Send the data or error to subscriptions in group.
     */
    const getDataForSubscriptions = (topic: string) => {
        const subscriptionsByTopic = subscriptions.filter((subscription) => {
            return subscription.topic === topic;
        });

        const subscriptionsByIdentifier: Record<FetchPolicy, Record<string, IRestApiSubscription[]>> = {
            [FetchPolicy.None]: {},
            [FetchPolicy.CacheAndNetwork]: {},
            [FetchPolicy.NetworkOnly]: {},
            [FetchPolicy.CacheOnly]: {},
        };

        subscriptionsByTopic.forEach((subscription) => {
            const fetchPolicy = subscription.params.fetchPolicy ?? defaultFetchPolicy!;
            const identifier = subscription.identifier;

            subscriptionsByIdentifier[fetchPolicy][identifier] = subscriptionsByIdentifier[fetchPolicy][identifier] ?? [];

            subscriptionsByIdentifier[fetchPolicy][identifier].push(subscription);
        });

        for (const fetchPolicyIn in subscriptionsByIdentifier) {
            const fetchPolicy = parseInt(fetchPolicyIn) as FetchPolicy;
            const subscriptions = subscriptionsByIdentifier[fetchPolicy];

            for (const identifier in subscriptions) {
                const subscription = subscriptions[identifier][0];

                if (fetchPolicy === FetchPolicy.CacheOnly || fetchPolicy === FetchPolicy.CacheAndNetwork) {
                    const data = getDataFromCache(identifier);

                    if (data || fetchPolicy === FetchPolicy.CacheOnly) {
                        subscriptions[identifier].forEach((subscription) => {
                            subscription.params.onData(data);
                        });
                    }
                }

                if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.NetworkOnly) {
                    const debouncer = brodcastDebouncersByFetchPolicyByTopic.current[fetchPolicy][identifier] ??= new Debouncer(kRestApiSubscriptionDebouncerDuration);

                    debouncer.run(() => {
                        restApiGet({
                            ...subscription.params,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: (loading: boolean) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.setLoading?.(loading);
                                });
                            },
                            onData: (data) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.onData(data);
                                });
                            },
                            onError: (error) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.onError?.(error);
                                });
                            },
                        });
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (topicsToGetDataForSubscriptions.length > 0 && authIdToken) {
            topicsToGetDataForSubscriptions.forEach((topic) => {
                getDataForSubscriptions(topic);
            });

            setTopicsToGetDataForSubscriptions([]);
        }
    }, [topicsToGetDataForSubscriptions, authIdToken]);

    /**
     * Get Subscriptions by identifier.
     * Group by identifier.
     * Call getData on each group.
     * Send the data or error to subscriptions in group.
     */
    const getDataForSubscriptionsByIdentifier = (identifier: string) => {
        const subscriptionsByIdentifier = subscriptions.filter((subscription) => {
            return subscription.identifier === identifier;
        });

        const subscriptionsByFetchPolicy: Record<FetchPolicy, Record<string, IRestApiSubscription[]>> = {
            [FetchPolicy.None]: {},
            [FetchPolicy.CacheAndNetwork]: {},
            [FetchPolicy.NetworkOnly]: {},
            [FetchPolicy.CacheOnly]: {},
        };

        subscriptionsByIdentifier.forEach((subscription) => {
            const fetchPolicy = subscription.params.fetchPolicy ?? defaultFetchPolicy!;
            const identifier = subscription.identifier;

            subscriptionsByFetchPolicy[fetchPolicy][identifier] = subscriptionsByFetchPolicy[fetchPolicy][identifier] ?? [];

            subscriptionsByFetchPolicy[fetchPolicy][identifier].push(subscription);
        });

        for (const fetchPolicyIn in subscriptionsByFetchPolicy) {
            const fetchPolicy = parseInt(fetchPolicyIn) as FetchPolicy;
            const subscriptions = subscriptionsByFetchPolicy[fetchPolicy];

            for (const identifier in subscriptions) {
                const subscription = subscriptions[identifier][0];

                if (fetchPolicy === FetchPolicy.CacheOnly || fetchPolicy === FetchPolicy.CacheAndNetwork) {
                    const data = getDataFromCache(identifier);

                    if (data || fetchPolicy === FetchPolicy.CacheOnly) {
                        subscriptions[identifier].forEach((subscription) => {
                            subscription.params.onData(data);
                        });
                    }
                }

                if (fetchPolicy === FetchPolicy.CacheAndNetwork || fetchPolicy === FetchPolicy.NetworkOnly) {
                    const debouncer = brodcastDebouncersByFetchPolicyByIdentifier.current[fetchPolicy][identifier] ??= new Debouncer(kRestApiSubscriptionDebouncerDuration);

                    debouncer.run(() => {
                        restApiGet({
                            ...subscription.params,
                            fetchPolicy: FetchPolicy.NetworkOnly,
                            setLoading: (loading: boolean) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.setLoading?.(loading);
                                });
                            },
                            onData: (data) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.onData(data);
                                });
                            },
                            onError: (error) => {
                                subscriptions[identifier].forEach((subscription) => {
                                    subscription.params.onError?.(error);
                                });
                            },
                        });
                    });
                }
            }
        }
    };

    useEffect(() => {
        if (identifiersToGetDataForSubscriptions.length > 0 && authIdToken) {
            identifiersToGetDataForSubscriptions.forEach((identifier) => {
                getDataForSubscriptionsByIdentifier(identifier);
            });

            setIdentifiersToGetDataForSubscriptions([]);
        }
    }, [identifiersToGetDataForSubscriptions, authIdToken]);

    /**
     * Create and save subscription.
     * Create Debouncer for identifier if not exists.
     */
    const subscribe = (
        topic: string,
        params: IRestApiParams,
        validateEvent: (notification: IEventSystemNotification[]) => boolean,
    ): IRestApiSubscription => {
        const identifier = createIdentifier('get', params.uri, params.params ?? {});

        const uuid = uuidv4();

        /**
         * Cancel subscription by removing it from subscriptions array.
         */
        const cancel = () => {
            setSubscriptions((prev) => {
                return prev.filter((subscription) => {
                    return subscription.uuid !== uuid;
                });
            });
        };

        const subscription: IRestApiSubscription = {
            uuid,
            topic,
            identifier,
            params,
            cancel,
            validateEvent,
        };

        setSubscriptions((prev) => {
            return [...prev, subscription];
        });

        return subscription;
    };

    useEffect(() => {
        const notInitialized = subscriptions.filter((subscription) => {
            return !subscription.initialized;
        });

        if (notInitialized.length > 0) {
            setSubscriptions((prev) => {
                const identifiersFor = prev.filter((subscription) => {
                    return !subscription.initialized;
                }).map((subscription) => {
                    return subscription.identifier;
                });

                setTimeout(() => {
                    setIdentifiersToGetDataForSubscriptions((prev) => {
                        return uniqueArray([...prev, ...identifiersFor]);
                    });
                }, 1);

                return prev.map((subscription) => {
                    return {
                        ...subscription,
                        initialized: true,
                    };
                });
            });
        }
    }, [subscriptions]);

    const restApiClientContext: IRestApiClientContext = {
        restUriBuilder,
        subscriptions,
        restApiGet,
        restApiPost,
        restApiDelete,
        getDataForSubscriptions,
        subscribe,
    };

    return (
        <>
            <RestApiClientContext.Provider value={restApiClientContext}>
                {children}
            </RestApiClientContext.Provider>

            <EventSystemListeners
                subscriptions={subscriptions}
                setIdentifiersToGetDataForSubscriptions={setIdentifiersToGetDataForSubscriptions}
            />
        </>
    );
}

export interface IRestApiParams {
    uri: string;
    headers?: Record<string, string>;
    params?: Record<string, any>;
    fetchPolicy?: FetchPolicy;
    setLoading?: (loading: boolean) => void;
    onData: (data: any) => void;
    onError?: (error: Error) => void;
}

export const kRestApiSubscriptionDebouncerDuration = 50;

export interface IRestApiSubscription {
    uuid: string;
    topic: string;
    identifier: string;
    params: IRestApiParams;
    cancel: () => void;
    validateEvent: (notification: IEventSystemNotification[]) => boolean;
    initialized?: boolean;
}

/**
 * Create identifier from topic and parameters.
 */
function createIdentifier(
    type: 'get' | 'post' | 'delete',
    uri: string,
    params: Record<string, string>,
): string {
    const paramsString = JSON.stringify(params);

    return `${type}#${uri}#${paramsString}`;
}

interface IEventSystemListenersProps {
    subscriptions: IRestApiSubscription[];
    setIdentifiersToGetDataForSubscriptions: Dispatch<SetStateAction<string[]>>;
}

/**
 * Component for EventSystem listeners.
 */
function EventSystemListeners(props: IEventSystemListenersProps) {
    const {subscriptions, setIdentifiersToGetDataForSubscriptions} = props;

    useEffect(() => {
        const eventSystemListeners: IEventSystemListener[] = [];

        const allIdentifiers = uniqueArray(subscriptions.map((subscription) => {
            return subscription.identifier;
        }));

        for (const identifier of allIdentifiers) {
            const subscriptionsByIdentifier = subscriptions.filter((subscription) => {
                return subscription.identifier === identifier;
            });

            eventSystemListeners.push({
                topic: subscriptionsByIdentifier[0].topic,
                callback: (notifications: IEventSystemNotification[]) => {
                    let trigger = false;

                    for (const subscription of subscriptionsByIdentifier) {
                        if (subscription.validateEvent(notifications)) {
                            trigger = true;
                            break;
                        }
                    }

                    if (trigger) {
                        setIdentifiersToGetDataForSubscriptions((prev) => {
                            return [...prev, identifier];
                        });
                    }
                },
            });
        }

        eventSystemListeners.forEach((eventSystemListener) => {
            listenToEventSystem(eventSystemListener);
        });

        return () => {
            eventSystemListeners.forEach((eventSystemListener) => {
                unListenToEventSystem(eventSystemListener);
            });
        };
    }, [subscriptions, setIdentifiersToGetDataForSubscriptions]);

    return null;
}
