import ResponsiveContainer from "../../components/screens/ResponsiveContainer";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import FormBuilder, {IInputsData, InputType, ValidateForm} from "../../components/form/FormBuilder";
import FullSizedContainer from "../../components/FullSizedContainer";
import AppPaper from "../../components/paper/AppPaper";
import {Box, Grid, Theme} from "@mui/material";
import AppButton from "../../components/buttons/AppButton";
import {useNavigate} from "react-router-dom";
import {tt} from "../../../core/Localization";
import PaperAppbar from "../../components/paper/PaperAppbar";
import {kWelcomeRoute} from "../WelcomeScreen";
import {kForgottenPasswordRoute} from "./ForgottenPasswordScreen";
import LanguagesMenu from "../../components/menus/LanguagesMenu";
import LogoWithTextComponent from "../../components/LogoWithText";
import ContentPadding from "../../components/paper/ContentPadding";
import {kDashboardRoute} from "../dashboard/DashboardScreen";
import TextDivider from "../../components/decorations/TextDivider";
import SocialButton from "../../components/buttons/SocialButton";
import Icons8Google from "../../../icons/Icons8Google";
import Icon8Facebook from "../../../icons/Icons8Facebook";
import Icons8AppleLogo from "../../../icons/Icons8AppleLogo";
import {makeStyles} from "tss-react/mui";
import {LoginProvider} from "../../../generated/graphql/graphql";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {
    AuthUserSignInSocial,
    AuthUserSignInSocialRedirectResult,
    FirebaseAuthErrorMessage,
    LinkAuthProvidersCancel,
    LinkAuthProvidersStart
} from "../../../service/AuthUserService";
import {AppDataContext} from "../../../AppData";
import {kContentWidthNarrow} from "../../../styles/AppThemeProcessor";
import DarkModeMenu from "../../components/menus/DarkModeMenu";
import {trackDataLayerEvent} from "../../../service/AnalyticsService";
import {checkAppVersion, clearLastCheckedVersionAt} from "../../../service/AppVersionService";
import InfoSection from "../../components/infos/InfoSection";

export const kSignInRoute = '/sign-in';


export const useStyles = makeStyles()((theme: Theme) => ({
        socialButton: {
            marginRight: 4,
            marginLeft: 4,
            marginBottom: 8,
        }
    })
);

/**
 * Screen component for signIn to the Firebase auth.
 */
export default function SignInScreen() {
    const appContext = useContext(AppContext);
    const {setTitle} = appContext;

    const appDataContext = useContext(AppDataContext);

    useEffect(() => {
        setTitle(tt('signIn.screen.title'));
    }, []);

    useEffect(() => {
        AuthUserSignInSocialRedirectResult(appDataContext);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            clearLastCheckedVersionAt();

            checkAppVersion(appDataContext);
        }, 1);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <ResponsiveContainer
            smallPhoneScreen={<Body isMobile={true}/>}
            largePhoneScreen={<Body isMobile={true}/>}
            tabletScreen={<Body/>}
            smallDesktopScreen={<Body/>}
            largeDesktopScreen={<Body/>}
            extraLargeDesktopScreen={<Body/>}/>
    );
}

interface IBodyProps {
    isMobile?: boolean;
}

/**
 * SignIn screen body contents.
 */
function Body(props: IBodyProps) {
    const {isMobile} = props;

    const appContext = useContext(AppContext);
    const {setCurrentLoginProvider, authUser} = appContext;

    const appDataContext = useContext(AppDataContext);

    const navigate = useNavigate();
    const {classes} = useStyles();

    const [signInLoading, setSignInLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<IInputsData>({
        username: {
            type: InputType.Text,
            label: `${tt('common.email')}*`,
            value: '',
            required: true,
            requireEmail: true,
        },
        password: {
            type: InputType.Password,
            label: `${tt('common.password')}*`,
            value: '',
            required: true,
        },
    });

    /**
     * SignIn with Firebase Auth.
     */
    const SignIn = async (provider: LoginProvider) => {
        trackDataLayerEvent('sign_in');

        LinkAuthProvidersCancel();

        setCurrentLoginProvider(provider);

        if (provider === LoginProvider.Email) {
            if (ValidateForm(inputs, setInputs)) {
                try {
                    setSignInLoading(true);

                    await signInWithEmailAndPassword(
                        getAuth(),
                        inputs.username.value,
                        inputs.password.value,
                    );
                } catch (e) {
                    setSignInLoading(false);
                    console.error(e);

                    if (FirebaseAuthErrorMessage(e, true)) {
                        LinkAuthProvidersStart(appDataContext, e);
                    }
                }
            }
        }

        AuthUserSignInSocial(
            appDataContext,
            provider
        );
        /* AuthUserSignInSocialPopup(
            appDataContext,
            provider
        ); */
    };

    useEffect(() => {
        if (authUser && authUser.signInResponse && !authUser.signInResponse.error && authUser.signInResponse.data) {
            navigate(kDashboardRoute);
        }
    }, [authUser]);

    return (
        <FullSizedContainer topAlignedContent={isMobile}>
            <Box sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
                <InfoSection />
            </Box>

            <AppPaper
                showPaddingTop={true}
                sx={{maxWidth: isMobile ? undefined : kContentWidthNarrow}}>
                <PaperAppbar
                    isMobile={isMobile}
                    title={tt('signIn.screen.title')}
                    backRoute={kWelcomeRoute}
                    children={<><LanguagesMenu/><DarkModeMenu/></>}/>
                <ContentPadding isNarrow={isMobile}>
                    <LogoWithTextComponent/>
                    <FormBuilder inputs={inputs} setInputs={setInputs}/>

                    <AppButton
                        isLoading={signInLoading}
                        onClick={() =>
                            SignIn(LoginProvider.Email)}
                        fullWidth={true}
                        variant="contained"
                    >{tt('signIn.screen.signIn')}</AppButton>
                    <Box height={8}/>
                    <AppButton
                        fullWidth={true}
                        variant="text"
                        onClick={() => navigate(kForgottenPasswordRoute)}>
                        {tt('signIn.screen.forgottenPassword')}
                    </AppButton>
                    <Box pb={2.5}/>
                    <TextDivider text={tt('common.dividerText.or')}/>
                    <Box pb={3.5}/>
                    <Grid container justifyContent={"center"}>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignIn(LoginProvider.Google)}
                            className={classes.socialButton} text={tt('common.google')}
                            icon={<Icons8Google/>}/>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignIn(LoginProvider.Facebook)}
                            className={classes.socialButton} text={tt('common.facebook')}
                            icon={<Icon8Facebook/>}/>
                        <SocialButton
                            fullWidth={isMobile}
                            onClick={() => SignIn(LoginProvider.Apple)}
                            className={classes.socialButton} text={tt('common.apple')}
                            icon={<Icons8AppleLogo/>}/>
                    </Grid>

                    {/*<Box pb={2}/>

                    <Box sx={{textAlign: 'center'}}>
                        <NavLink to={alternativeSignInRoute()}>
                            {tt('navigate.alternativeSignIn')}
                        </NavLink>
                    </Box>*/}
                </ContentPadding>
            </AppPaper>
        </FullSizedContainer>
    );
}
